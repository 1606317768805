import React, { FunctionComponent } from 'react'
import { BreadcrumbJson } from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'

interface PuxStructuredDataType {
  structuredData: StructuredDataType
}

type StructuredDataType = Record<string, unknown> | BreadcrumbJson

const PuxStructuredData: FunctionComponent<PuxStructuredDataType> = (props) => {
  const getStructuredData = (data: StructuredDataType): string => {
    return JSON.stringify(data)
  }

  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: getStructuredData(props.structuredData),
      }}
    />
  )
}

export default PuxStructuredData
