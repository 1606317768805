import "./PuxTopImage.scss";

import React, { FunctionComponent } from "react";
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from "Shared/components/PuxWysiwyg/PuxWysiwyg";

import PuxMediaSelector, {
  PuxMediaSelectorType,
} from "../content/mediaImage/PuxMediaSelector";
import PuxButton, { PuxButtonType } from "../PuxButton/PuxButton";
import PuxIconBuilder from "../PuxIconBuilder/PuxIconBuilder";
import PuxVideo, { PuxVideoType } from "../PuxVideo/PuxVideo";

export enum TopImageContentAlign {
  LEFT = `left`,
  CENTER = `center`,
}

export enum TopImageContentColor {
  DEFAULT = `none`,
  WHITE = `white`,
}

type TopImageButtonAlign = TopImageContentAlign;

export enum TopImageImageStyle {
  BACKGROUND_GRADIENT = `background-gradient`,
  BACKGROUND = `background`,
  INSIDE = `inside`,
}

export interface PuxTopImageType {
  contentItemId?: string;
  displayText: string;
  topImageContent: PuxWysiwygHtmlBodyType;
  topImageContentAlign: TopImageContentAlign;
  topImageContentColor: TopImageContentColor;
  topImageButtonAlign: TopImageButtonAlign;
  topImageBenefit1: PuxWysiwygHtmlBodyType;
  topImageBenefit2: PuxWysiwygHtmlBodyType;
  topImageImageStyle: TopImageImageStyle;
  topImageButton: PuxButtonType;
  topImageSecondaryButton: PuxButtonType;
  topImageBackground: PuxMediaSelectorType;
  puxVideo: PuxVideoType;
}

const rootClass = `topimage`;

const PuxTopImage: FunctionComponent<PuxTopImageType> = (props) => {
  const hasTopImageVideo = (url: string) => {
    return url ? TopImageImageStyle.INSIDE : props.topImageImageStyle;
  };

  let contentColorClass: string;
  switch (props.topImageContentColor ?? TopImageContentColor.DEFAULT) {
    case TopImageContentColor.WHITE:
      contentColorClass = `is-white`;
      break;
    default:
      contentColorClass = ``;
      break;
  }

  const classes =
    `${rootClass}-${props.topImageContentAlign} ` +
    `${rootClass}-${hasTopImageVideo(props.puxVideo?.videoYTID?.html)} ` +
    `${rootClass}-container`;

  return (
    <div className={rootClass}>
      <div className={classes}>
        <div className={`${rootClass}-image`}>
          <PuxMediaSelector
            disableLazyload={true}
            {...props.topImageBackground}
          />
          {props.puxVideo?.videoYTID?.html && (
            <PuxVideo
              videoStructuredDataContentUrl={
                props.puxVideo?.videoStructuredDataContentUrl
              }
              videoStructuredDataDescription={
                props.puxVideo?.videoStructuredDataDescription
              }
              videoStructuredDataName={props.puxVideo?.videoStructuredDataName}
              videoStructuredDataUploadDate={
                props.puxVideo?.videoStructuredDataUploadDate
              }
              videoThumbnail={props.puxVideo?.videoThumbnail}
              videoYTID={props.puxVideo?.videoYTID}
            />
          )}
        </div>

        <div className={`${rootClass}-gradient ${contentColorClass}`}></div>

        <div className={`${rootClass}-foreground widget-container-padding`}>
          <div className={`${rootClass}-limiter`}>
            <div className={`${rootClass}-content ${contentColorClass}`}>
              <div className={`${rootClass}-description`}>
                <PuxWysiwyg content={props.topImageContent} />
              </div>
              {props.topImageBenefit1?.html.trim() && (
                <div className={`${rootClass}-benefit`}>
                  <PuxIconBuilder icon="CheckIcon" />
                  <PuxWysiwyg inline content={props.topImageBenefit1} />
                </div>
              )}
              {props.topImageBenefit2?.html.trim() && (
                <div className={`${rootClass}-benefit`}>
                  <PuxIconBuilder icon="CheckIcon" />
                  <PuxWysiwyg inline content={props.topImageBenefit2} />
                </div>
              )}
            </div>
            <div
              className={`${rootClass}-buttons ${
                props.topImageButtonAlign
                  ? `buttons-` + props.topImageButtonAlign
                  : ``
              }`}
            >
              <PuxButton {...props.topImageButton} />
              {props.topImageSecondaryButton && (
                <PuxButton {...props.topImageSecondaryButton} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PuxTopImage;
